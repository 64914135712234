import * as Sentry from '@sentry/react';

interface User {
  email: string;
  firstName?: string;
  lastName?: string;
  profileApproved?: boolean;
}

export const sentrySetUser = ({
  email,
  firstName = null,
  lastName = null,
  profileApproved = null
}: User) => {
  Sentry.setUser({
    email,
    firstName,
    lastName,
    profileApproved
  });
};
