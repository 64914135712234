import React, {ReactNode} from 'react';
import style from './style.module.scss';

interface InfoRowProps {
  label: string;
  children: ReactNode;
}

export const InfoRow = ({label, children}: InfoRowProps) => (
  <div className={style.infoRow}>
    <div className={style.label}>{label}</div>
    <div className={style.entry}>{children}</div>
  </div>
);
